import React from 'react';
import { Route } from 'react-router-dom';
import AdminIndex from './SiteAdmin/containers/adminindex'
import SMSClientList from './SiteAdmin/containers/smsclientlist'
import MobileClientDetail from './SiteAdmin/containers/MobileClientDetailView'
import ClientTransactionstatement from './SiteAdmin/containers/clientTransactiostatementreport'
import ApplicationDetail from './SiteAdmin/containers/ApplicationDetailView'

import clientcouserList from './SiteAdmin/containers/clientco_users'

const AdminBaseRouter = () =>(

     <div>
        <Route exact path='/admin/' component={AdminIndex} />
        <Route exact path='/' component={AdminIndex}  />
        <Route exact path='/mobileclients/' component={SMSClientList}  />
        <Route exact path='/mobileclients/:clientId' component={MobileClientDetail}  />
        <Route exact path='/clienttransactionsreport/' component={ClientTransactionstatement}  />
        <Route exact path='/applicationdetails/' component={ApplicationDetail}  />
        <Route exact path='/clientco_users/:clientId' component={clientcouserList}  />
    </div>
    
);

export default AdminBaseRouter;



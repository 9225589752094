import React from 'react';
import { Form, Input, Button,Select,message,Spin } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';

const dateFormat = 'HHmmssDDMMYYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''


class SMSClientCreateForm extends React.Component {
  state = {
    clientname:'',  
    companyemail:'',
    companylogo:null,   
    companyphone:'',
    boxnumber:'',
    datarequested:true,  
    sms_price:0,
  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

    this.setState({datarequested:false});
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('client_name', this.state.clientname);
    form_data.append('company_email', this.state.companyemail);
    form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
    form_data.append('box_number', this.state.boxnumber);

    this.state.companylogo==null?
    console.log("No profile file")
    :
    form_data.append('client_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
    
    form_data.append('is_active', false);
    form_data.append('sms_price', this.state.sms_price);

      axios.post(serverconfig.backendserverurl+'/api/smsclients/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      console.log(res.data)
      this.setState({datarequested:false})
      window.location.reload(false)
      message.info('Successfully created client')
      
    } )
    .catch(error => console.log(error))
  }


  handleclientnameChange= (e) => this.setState({ clientname: e.target.value});
  handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
  handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});
  handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
  handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div >
          <Form onFinish={(event) => this.handleFormSubmit(event)}>
            <FormItem label="Client Name"
                name="clientname"
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
              <Input name="clientname"  placeholder="Put a name here." value={this.state.clientname} onChange={this.handleclientnameChange} />
            </FormItem>
  
            <FormItem label="Client Logo">
              <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={this.handleCompanyLogoChange}/>
            </FormItem>  
            
            <FormItem label="Client Email"
                name="companyemail"
                rules={[
                  {
                    required: true,
                    message: 'Please input email',
                  },
                ]}
            >
              <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
            </FormItem> 
  
            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
             </FormItem>
             

            <FormItem label="Box number">
              <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
            </FormItem>
  

            <FormItem label="SMS price"
                name="sms_price"
                rules={[
                  {
                    required: true,
                    message: 'Please enter value',
                  },
                ]}            
            >
              <Input maxLength={6} name="sms_price" type='number'  placeholder="Value" value={this.state.sms_price} onChange={(val)=>{this.setState({sms_price:val.target.value})}} />
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit" >Create</Button>
            </FormItem>
          </Form>
        </div>
      );
    }

  }

}

export default SMSClientCreateForm;




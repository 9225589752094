import React from 'react';
import { Form, Input, Button,Select,message,Spin,Result,Card } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';

const dateFormattn = 'HHmmssDDMMYYYY';
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''


class SMSPaymentForm extends React.Component {

  state = {
    reason:'',  
    licence:'',
    remark:'',
    companylogo:null,   
    companyphone:'',
    amount:0,
    accountslimit:0,
    branchname:'',
    website:'',
    address:'',
    datarequested:true,  
    safebalance:0,
    sharevalue:0,
    retainedearnings:0,
    packages:[],
    package:'',
    apicode:'',
    smsclients:[],
    datasubmittedsuccessfully:false,
    message:''

  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+'/api/smsclients/')
    .then(res => {
        this.setState({
          smsclients:res.data
        })

        this.setState({datarequested:false})
    })

}

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }



  //submit button pressed
  handleFormSubmit=(event) =>{
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    if(this.state.amount===0 || this.state.amount<500){
      alert("amount cant be zero or less than 500 shs")
    }else if(this.state.companyphone===''){
      alert("Phone number cant be empty")
    }else{


      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('reason', this.state.reason);
      form_data.append('remark', this.state.remark);
      form_data.append('phone', this.state.companyphone);
      form_data.append('amount', this.state.amount);
      form_data.append('requestfrom', "smsdashboard");
      form_data.append('transactionId', "TXN"+moment().format(dateFormattn).toString());
      form_data.append('date', moment().format(dateFormat).toString());
      form_data.append('apicode', this.state.apicode);
  
      axios.post(serverconfig.backendserverurl+'/customqueries/makeclientsmspayment', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({reason:''})
      this.setState({remark:''})
      this.setState({companyphone:''})
      this.setState({amount:0})
  
      this.setState({message:res.data.message})
      this.setState({datasubmittedsuccessfully:true})
  
     } 
    )
    .catch(error => console.log(error))

    }


  }

  handlereasonChange= (e) => this.setState({ reason: e.target.value});
  handleremarkChange= (e) => this.setState({ remark: e.target.value});
  handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
  handleCompanyamountChange= (e) => this.setState({ amount: e.target.value});
  handleclientChange= (e) => this.setState({ apicode: e});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title={this.state.message}
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        return (
          <div >
            <h3>SMS payment via mobile money</h3>
            <Form
            onFinish={(event) => this.handleFormSubmit(event)}
            >
  
            <FormItem label="Client"
              name="mobileclient"
              rules={[
                {
                  required: true,
                  message: 'Please Select Client',
                },
              ]}
               >
                  <Select 
                  placeholder="SMS Client" 
                  //style={{ width: 120 }} 
                  value={this.state.apicode} 
                  onChange={this.handleclientChange}
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                  >
                    {this.state.smsclients.map(
                      (clnt)=>(
                        <Option value={clnt.apicode}>{clnt.client_name}</Option>
                      ))}
                  </Select>
              </FormItem>
  
              <FormItem label="Phone Number">
              <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="UG"
                    placeholder="Enter phone number"
                    value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
               </FormItem>
  
               
              <FormItem label="Amount">
                <Input name="amount" type='number'  placeholder="Amount here" value={this.state.amount} onChange={this.handleCompanyamountChange} />
              </FormItem>
  
              <FormItem label="Reason"
                  name="reason"
                  rules={[
                    {
                      required: true,
                      message: 'Please input reason',
                    },
                  ]}
                >
                <Input name="reason"  placeholder="Put reason here." value={this.state.reason} onChange={this.handlereasonChange} />
              </FormItem>
    
              <FormItem label="Remark"
                  name="remark"
                  rules={[
                    {
                      required: true,
                      message: 'Please input remark',
                    },
                  ]}
              >
                <Input name="remark" placeholder="Put remark here" value={this.state.remark} onChange={this.handleremarkChange} />
              </FormItem> 
  
              <FormItem>
                <Button type="primary" htmlType="submit" >Submit</Button>
              </FormItem>
            </Form>
          </div>
        );


      }



    }
  }

}


export default SMSPaymentForm;




import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Form,InputNumber,Modal,Popover,Spin,Popconfirm,message,Tooltip,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,DollarOutlined,RedoOutlined,FundViewOutlined,LoadingOutlined,MehFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../views/serverconn'
import SMSPaymentForm from '../components/smspaymentform'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}

var token= ''

class Smsclientpaymentlist extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    smsmobilepayments: [],
    datarequested:true,
    walletmodalvisible:false,
    selectedsaccooject:{},
    date:moment().format(dateFormat).toString(),
    saccodebitamount:0,
    mobilephone:''
  };

 

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+'/api/smsmobilepayments/')
    .then(res => {
        this.setState({
          smsmobilepayments:res.data
        })

        this.setState({datarequested:false})
    })

}



  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };



  handlemobilephoneChange= (e) => this.setState({ mobilephone: e});
  handlesaccodebitamountChange=(e)=>this.setState({saccodebitamount:e})


  render() {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Client',
        dataIndex: 'smsclientname',
        key: 'id',
        ...this.getColumnSearchProps('smsclientname'),
      },
      {
        title: 'Phone contact',
        dataIndex: 'Phone',
        key: 'id',
      },
      {
        title: 'Amount',
        dataIndex: 'Amount',
        key: 'id',
        render:(text)=>
        <p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
      }, 
      {
        title: 'Transaction ID',
        dataIndex: 'transactionID',
        key: 'id',
        ...this.getColumnSearchProps('transactionID'),
      },
      {
        title: 'Telcom transaction ID',
        dataIndex: 'telecomtransactionID',
        key: 'id',
        ...this.getColumnSearchProps('telecomtransactionID'),
      },   
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
        ...this.getColumnSearchProps('status'),
      },  
      
    ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="SMS Client payments" key="1">
            <Table 
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.smsmobilepayments}
            scroll={{ x: 1000 }}
            bordered/>
            <br></br>
            <SMSPaymentForm />

            </Panel>
            </Collapse>
            <br />

        </div>
    )

    }

  
  }
}

export default Smsclientpaymentlist; 


import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Divider,Statistic,Collapse,Avatar,Tooltip,Button } from 'antd';
import { ArrowUpOutlined, SearchOutlined,ArrowDownOutlined,CreditCardFilled,MessageFilled,LoadingOutlined,
   UserOutlined, AccountBookOutlined,SlidersFilled,MinusCircleFilled,BankFilled,DropboxOutlined,
   DollarCircleOutlined,DownloadOutlined,GroupOutlined,PrinterOutlined,PlusCircleFilled,FileFilled,DollarCircleFilled,MinusSquareFilled,FundFilled,PlusSquareFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../../views/serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import { NavLink } from 'react-router-dom'
import { Table, Input,Popover,Modal,Tabs
  ,Card,Form,Select,InputNumber,Drawer,Descriptions,Popconfirm,message,Spin,DatePicker } from 'antd';
import SMSClientCreateForm from '../components/SMSClientCreateForm'
import Highlighter from 'react-highlight-words';
import SMSClientList from './smsclientlist'
import SMSVendorList from './smsvendorlist'
import Smsclientpaymentlist from './smsclientpaymentlist'

import ReactExport from "@ibrahimrahmani/react-export-excel";
import ReactToPrint from 'react-to-print';
const { RangePicker } = DatePicker;
const FormItem=Form.Item;

const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { Panel } = Collapse;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;
const { Option } = Select;

var token= ''

class AdminIndex extends React.Component {

  state = {
    expenses:[],
    searchText: '',
    searchedColumn: '',
    date:moment().format(dateFormat).toString(),
    yearlymonths:[],
    yearlymonthlysales:[],
    totalnoofclients:0,
    monthlyinvoicetotalamount:0,
    showprintermodal:false,
    invoicesbycompany:[],
    companies:[],
    invoicetotals:[],
    licencedcompanies:[],
    companylicences:[],
    companyprofile:{},
    datarequested:true,
    showpackagecreatemodal:false,
    showwithdrawmodal:false,
    showcollectionmodal:false,
    pendingmessages:[],
    successmessages:[],
    failedtransactions:[],

    dateone:'',
    datetwo:'',
    smsclients:[],
    selected_client:''
  };

  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    console.log("token: "+token)

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/smsclients/`)
    .then(res => {

        this.setState({smsclients:res.data})

        this.setState({
          totalnoofclients:res.data.length
        })
    })


    //get the company profile here
    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/smss/?status=${'pending'}`)
    .then(res => {
        this.setState({
          pendingmessages:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/smss/?status=${'success'}`)
    .then(res => {
        this.setState({
          successmessages:res.data
        })
    })

}

//display sacco create modal
displaypaymentmodal=()=>{
  this.setState({showprintermodal:true})

}

closeprintermodal=()=>{
  this.setState({showprintermodal:false})
}


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ?(
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});


render() {
  var columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ...this.getColumnSearchProps('date'),
    },
    {
      title: 'Client',
      dataIndex: 'smsclientname',
      key: 'id',
      ...this.getColumnSearchProps('smsclientname'),
    },
    {
      title: 'Phone contact',
      dataIndex: 'number',
      key: 'id',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'id',
      ...this.getColumnSearchProps('message'),
    },

    {
      title: 'Vendor name',
      dataIndex: 'smsvendorname',
      key: 'id',
      ...this.getColumnSearchProps('smsvendorname'),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'id',
      render:(text,record)=>
      <p style={{color:record.status=="success"?"green":'orange'}}>{text}</p>
    },
  ];  


  if(this.state.datarequested===true){
    return(
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
      <Spin indicator={antIcon} />
      </div>
    )

  }else{

    return(
      
      <div className="animated fadeIn">
      <Row>
      <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
       <Card
          //onClick={()=>{}}
          hoverable
          style={{ 
            background:"white",
            display: 'flex',
            justifyContent:'center',
            padding:4,
            alignItems:'center' }}
          cover={<GroupOutlined  style={{color:'#154360',fontSize: '50px'}} />} 
        >
          <Meta title={<h3 style={{color:"#154360"}}><Button type='primary' onClick={() => this.displaypaymentmodal()}>Add Mobile Client</Button></h3>} description={<h5><NavLink to='/mobileclients/'>{this.state.totalnoofclients} CLIENTS </NavLink></h5>} />
        </Card>
       </Col>

       </Row>

       <Tabs  type="card">
        
          <TabPane tab="Pending messages" key="1" defaultActiveKey="1">
              <Collapse onChange={this.callback} defaultActiveKey={['1']}>
                <Panel header="Pending messages" key="1">
                <Table columns={columns} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.pendingmessages}
                scroll={{ x: 1000 }}
                bordered/>
                </Panel>
              </Collapse>            
          </TabPane>

          <TabPane tab="Sent Messages" key="2" >

          <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}> 
                   
              <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>}>Download report (Excel)</Button>}>
                <ExcelSheet data={this.state.successmessages} name="SMS report">
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Client" value="smsclientname"/>
                    <ExcelColumn label="Receiver" value="number"/>
                    <ExcelColumn label="Message" value="message"/>
                    <ExcelColumn label="Vendor" value="smsvendorname"/>
                </ExcelSheet>
            </ExcelFile>

            &nbsp;&nbsp;&nbsp;
            <FormItem label="Client">
                <Select 
                placeholder="SMS Client" 
                //style={{ width: 120 }} 
                value={this.state.selected_client} 
                onChange={(val)=>{this.setState({selected_client:val})}}
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                >
                  {this.state.smsclients.map(
                    (clnt)=>(
                      <Option value={clnt.id}>{clnt.client_name}</Option>
                    ))}
                </Select>
            </FormItem>
            &nbsp;&nbsp;&nbsp;

            <Form  layout="inline" >
            <FormItem label="Date Range">
                <RangePicker onChange={(date, dateString) =>{
                this.setState({ dateone: dateString[0]});
                this.setState({ datetwo: dateString[1]});
              }} format={dateFormat} />
            </FormItem>


            <FormItem>
            <Button onClick={()=>{
           
            if(this.state.dateone==='' || this.state.datetwo===''){
            alert("Date one or two missing")
            }else{
           // this.setState({datarequested:true})

            let form_data1 = new FormData();
            form_data1.append('dateone', this.state.dateone);
            form_data1.append('datetwo', this.state.datetwo);
            form_data1.append('selected_client', this.state.selected_client);

            axios.post(serverconfig.backendserverurl+`/customqueries/getsms_report`, form_data1)
            .then(res => {
              this.setState({successmessages:JSON.parse(res.data.report)})
              //this.setState({datarequested:false})

            })}

            }} type="primary" htmlType="button">Search</Button>
            </FormItem> 
            </Form>
            </div> 
            <br></br>


              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                <Panel header="Sent Messages" key="1">
                <Table columns={columns} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.successmessages}
                scroll={{ x: 1000 }}
                bordered/>
                </Panel>
              </Collapse>
          </TabPane>


          <TabPane tab="SMS Clients" key="0" >
          <SMSClientList />
          </TabPane>

          <TabPane tab="SMS Vendors" key="00" >
          <SMSVendorList />
          </TabPane>


          <TabPane tab="Client SMS payments" key="000" >
          <Smsclientpaymentlist />
          </TabPane>

            </Tabs>
       
       <Modal              
          visible={this.state.showprintermodal}
          title="SMS Client Registration"
          width={600}
          onCancel={this.closeprintermodal}
          footer={[
            <Button key="back" onClick={this.closeprintermodal}>
              Cancel
            </Button>
            ]}
            >
              <SMSClientCreateForm />                
      </Modal>

      </div>
      )
  }
  
  }
}

export default AdminIndex; 

import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Avatar,Spin,Image,Modal,Input,message,Select,Checkbox,Switch } from 'antd';
import CompanyProfileForm from '../components/CompanyProfileForm'
import { UserOutlined } from '@ant-design/icons';
import { PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import ImgsViewer from 'react-images-viewer'
import PhoneInput from 'react-phone-number-input'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import {
    Col,
    Row,
  } from 'reactstrap';

const FormItem=Form.Item;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'HHmmssDDMMYYYY';

var token= ''


class MobileClientDetail extends React.Component{

    state={
        profile:{},
        clientname:'',  
        client_logo:'',
        companyemail:'',
        companylogo:null,   
        companyphone:'',
        boxnumber:'',
        datarequested:true,  
        apicode:'',
        sms_balance:0,
        sms_price:0,
        activated:false,
        payment_update_url:'',

    }


    componentDidMount(){
        if(localStorage.getItem("token")){
            token= localStorage.getItem("token")
         }else{
            token= ''
         }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        //component mount taks
        this.componentmounttasks();

    }

    //component mount tasks
    componentmounttasks=()=>{
        const clientId=this.props.match.params.clientId;
        axios.get(`${serverconfig.backendserverurl}/api/smsclients/${clientId}`)
        .then(res => {  
            this.setState({
                profile: res.data
            })

            this.setState({clientname:res.data.client_name})
            this.setState({client_logo:res.data.client_logo})
            this.setState({companyemail:res.data.company_email})
            this.setState({companyphone:res.data.RegisteredPhoneNumber1})
            this.setState({boxnumber:res.data.box_number})
            this.setState({apicode:res.data.apicode})
            this.setState({sms_balance:res.data.sms_balance})
            this.setState({sms_price:res.data.sms_price})
            this.setState({payment_update_url:res.data.payment_update_url})

            

            this.setState({activated:res.data.activated})

            this.setState({datarequested:false})

        })


 
    }

    handleclientnameChange= (e) => this.setState({ clientname: e.target.value});
    handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
    handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});
    handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
    handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});
    handleCompanyPhonetwoChange= (e) => this.setState({ companyphonetwo: e});
    handleCompanyPhonethreeChange= (e) => this.setState({ companyphonethree: e});
    handleupdate_urlChange= (e) => this.setState({ update_url: e.target.value});
    handleretainedpercentageChange= (e) => this.setState({ retainedpercentage: e.target.value});



    //submit button pressed
    handleFormSubmit=() =>{
        const clientId=this.props.match.params.clientId;

        this.setState({datarequested:true})
        let form_data = new FormData();

        form_data.append('client_name', this.state.clientname);
        form_data.append('company_email', this.state.companyemail);
        form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
        form_data.append('box_number', this.state.boxnumber);
    
        this.state.companylogo==null?
        console.log("No profile file")
        :
        form_data.append('client_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
        
        form_data.append('is_active', true);
        form_data.append('sms_price', this.state.sms_price);
        form_data.append('sms_balance', this.state.sms_balance);
        form_data.append('payment_update_url', this.state.payment_update_url);

        
          axios.put(serverconfig.backendserverurl+`/api/smsclients/${clientId}/`, form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>{
            this.setState({datarequested:false})
            message.info('Successfully updated client')
            this.componentmounttasks();
        }
        )
        .catch(error => console.log(error))
    }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
          }else{
            return(
                <div>

                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card 
                            style={{padding:10}}
                            hoverable>
    
        <h4>Client Profile Update</h4>
        <Form
        onFinish={(event) => this.handleFormSubmit(event)}>
            <FormItem label="Client Name">
              <Input name="clientname"  placeholder="Put a name here." value={this.state.clientname} onChange={this.handleclientnameChange} />
            </FormItem>
  
            <FormItem label="Client Logo">
              <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={this.handleCompanyLogoChange}/>
            </FormItem>  
            
            <FormItem label="Client Email"
            
            >
              <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
            </FormItem> 
  
            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
             </FormItem>
             

            <FormItem label="Box number">
              <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
            </FormItem>
  

            <FormItem label="SMS price"
                        
            >
              <Input maxLength={6} name="sms_price" type='number'  placeholder="Value" value={this.state.sms_price} onChange={(val)=>{this.setState({sms_price:val.target.value})}} />
            </FormItem>

            <FormItem label="SMS balance">
                <Input name="sms_balance" type='number'  placeholder="Value" value={this.state.sms_balance} onChange={(val)=>{this.setState({sms_balance:val.target.value})}} />
            </FormItem>

            <FormItem label="Sms payment update link ">
                <Input name="payment_update_url"   placeholder="Value" value={this.state.payment_update_url} onChange={(val)=>{this.setState({payment_update_url:val.target.value})}} />
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit" >Update</Button>
            </FormItem>
            </Form>
                    </Card>
                       
    
                    </Col>

                    <Col xs="12" sm="6" lg="6">
                      <Card 
                            style={{padding:10,alignContent:'center',alignItems:'center'}}
                            hoverable
                            cover={
                            
                                <Image
                                width={100}
                                //height={'50%'}
                                src={this.state.profile.client_logo}    
                                />                   
                             }
                        >
                        </Card>


                   
                    </Col>
                
                    </Row>
    
                    <br/>
    
                </div>
            )

          }
     
    }
}

export default MobileClientDetail; 